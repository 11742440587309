.endingScreen {
    height: 100%;
    width: 100%;
    text-align: center;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    color: white;
}

.endingScreen h1{
    font-size: 5vw;
}

.options {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 7em;
}

.endingScreen .btn{
    font-size: 2em;
    padding: 0.5em;
    margin-bottom: 1em;
    border: 5px solid transparent;
}

.endingScreen .btn:hover{
    border: 5px solid black;
}

@media only screen and (max-width: 500px) {
    .endingScreen h1 {
        font-size: 3em;
        margin-bottom: 1em;
    }
 }

